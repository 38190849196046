<template>
	<div>
		<el-dialog
			title="Movimmientos"
			:visible="showDialog"
			@close="close"
			@open="create"
			:close-on-click-modal="false"
			:close-on-press-escape="false"
			width="80%"
		>
			<v-row dense>
				<v-col cols="12" xl="9" md="9" sm="9">
					<v-simple-table fixed-header dense height="400px">
						<template v-slot:default>
							<thead>
								<tr>
									<th colspan="11" class="text-center">ALQUILERES</th>
								</tr>
								<tr>
									<th class="text-center">HAB</th>
									<th class="text-center">T</th>
									<th>CLIENTE</th>
									<th>PROCEDENCIA</th>
									<th class="text-center">FECHA/HORA INGRESO</th>
									<th class="text-center">B/F</th>
									<th class="text-center">FECHA/HORA SALIDA</th>
									<th class="text-right">N° D</th>
									<th class="text-right">N° P</th>
									<th class="text-right">PRECIO</th>
									<th class="text-right">OBSERVACIONES</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(row, index) in record_rents" :key="index">
									<td scope="row" class="text-center">{{ row.room }}</td>
									<td scope="row" class="text-center">{{ row.room_type }}</td>
									<td scope="row">
										{{ row.customer }}
									</td>
									<td scope="row">{{ row.customer_address }}</td>
									<td scope="row" class="text-center">{{ row.date_of_entry }}</td>
									<td scope="row" class="text-center">{{ row.document }}</td>
									<td scope="row" class="text-center">{{ row.date_of_exit +' '+row.time_of_exit }}</td>
									<td class="text-right">
										{{ (row.period_id === '01') ? 0 : row.number_of_days }}
									</td>
									<td class="text-right">
										{{ row.number_of_people }}
									</td>
									<td class="text-right">
										<span class="text-success">{{ row.total }}</span>
									</td>
									<td>
										<span>{{ row.observations }}</span>
									</td>
								</tr>
							</tbody>
						</template>
					</v-simple-table>
				</v-col>
				<v-col cols="12" xl="3" md="3" sm="3">
					<v-simple-table fixed-header dense height="400px">
						<template v-slot:default>
							<thead>
								<tr>
									<th colspan="4" class="text-center">PRODUCTOS</th>
								</tr>
								<tr>
									<th class="text-center">CANT.</th>
									<th>PRODUCTO</th>
									<th class="text-right">TOTAL</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(row, index) in record_items" :key="index">
									<td scope="row" class="text-center">{{ row.quantity }}</td>
									<td scope="row">{{ row.name }}</td>
									<td class="text-right">
										<span class="text-success">{{ row.total }}</span>
									</td>
								</tr>
							</tbody>
						</template>
					</v-simple-table>
				</v-col>
			</v-row>
			<div class="row">
				

				<div
					class="col-xl-5 col-lg-6 col-md-12 col-sm-12 col-xs-12 offset-xl-7 offset-lg-6"
				>
					<div class="table-responsive">
						<v-simple-table dense>
							<template v-slot:default>
								<tbody>
									<tr>
										<td scope="row" class="text-right">Pagos con tarjeta:</td>
										<td class="text-right">{{ totals.payment_card }}</td>
									</tr>
									<tr>
										<td scope="row" class="text-right">Total ingresos:</td>
										<td class="text-right">{{ totals.total_income }}</td>
									</tr>
									<tr>
										<td scope="row" class="text-right">Total egresos:</td>
										<td class="text-right">{{ totals.total_expense }}</td>
									</tr>
									<tr>
										<td scope="row" class="text-right">Saldo final:</td>
										<td class="text-right">
											<strong>{{ totals.final_balance_frt }}</strong>
										</td>
									</tr>
								</tbody>
							</template>
						</v-simple-table>
					</div>
				</div>
			</div>
			<div class="text-center mb-3 mt-3">
				<el-button type="danger" @click.prevent="close()">Cerrar</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
// import VuePerfectScrollbar from 'vue-perfect-scrollbar';

export default {
	props: ['showDialog', 'recordId'],
	data() {
		return {
			loading: false,
			titleDialog: null,
			resource: 'openings',
			record_rents: [],
			record_items: [],
			totals: {
				total_rent: '0.00',
				total_items: '0.00',
				total_income: '0.00',
				final_balance_frt: '0.00',
			},
		};
	},
	// components: {
	// 	embPerfectScrollbar: VuePerfectScrollbar,
	// },
	methods: {
		async create() {
			await this.getRecords();
		},
		getRecords() {
			this.$http.get(`/${this.resource}/transactions/${this.recordId}`).then((response) => {
				this.totals = response.data.totals;
				this.record_rents = response.data.record_rents;
				this.record_items = response.data.record_items;
				this.load = true;
			});
		},
		close() {
			this.$emit('update:showDialog', false);
			this.records = [];
			this.totals = {};
		},
	},
};
</script>
